import { CloseOutlined, CloudTwoTone, DeleteOutlined, EditOutlined, EyeOutlined, HistoryOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Collapse, DatePicker, Form, Image, Input, Modal, notification, Pagination, Popconfirm, Row, Select, SelectProps, Space, Spin, Table, Tag, Tooltip } from 'antd';
import HTTPService from '../../../utils/makerequest';
import { IPageData } from '../../../interfaces/page-data';
import { useEffect, useRef, useState } from 'react';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import toast from 'react-hot-toast';
import { IAltisDataGridColumns, IDropdownItem } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import '../PrinterManagement/Device.css';
import { useTranslation } from 'react-i18next';
import { IInventory } from '../../../interfaces/IItemData';
import i18n from '../../../i18n';
import { IFilterOptions } from '../../../interfaces/IFilterOptions';
import moment from 'moment';
import { InventoryTracking } from '../../../interfaces/group';

const InventoryMovementManagement: React.FunctionComponent<any> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  // const [inventoryData, setInventoryData] = useState<IInventory>();
  const [filters, setFilters] = useState({ brands: [], colors: [] });
  const [inventoryMovementData, setInventoryMovementData] = useState({ data: [], count: 0 });
  const [inputValue, setInputValue] = useState('');
  const [barcodeValue, setBarcodeValue] = useState('');
  const [oldRecordValue, setOldRecordValue] = useState('');
  const [newRecord, setNewRecord] = useState('');
  const [createDate, setCreateDate] = useState(null);
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const { Panel } = Collapse;
  const dataGridRef = useRef<any>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({ brand: [], department: [], status: [], locations: [], category: [], supplier: [], campus: [], type: [],countReport:[]});

  const pageData: IPageData = {
    title: t('reportMovement'),
    loaded: true,
  };


  useEffect(() => {
    onSetPage(pageData);
    InventoryReportFilter();
    InventoryFilter();
  }, [i18n.language]);


  const handleSelectAll = (e) => {
    var details = { ...inventoryMovementData };
    if (e.target.checked) {
      details.data.map((row) => (row.isSelected = true));
    } else {
      details.data.map((row) => (row.isSelected = false));
    }

    setInventoryMovementData(details);
  };

  const handleCheckboxChange = (value: number) => {
    setSelectedValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((v) => v !== value);
      } else {
        return [...prev, value];
      }
    });
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trim = e.target.value.trim()
    setBarcodeValue(trim);
  };


  const handleRecordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trim = e.target.value.trim()
    setOldRecordValue(trim);
  };

  const handleRowSelect = (e, index) => {
    var newObject = { ...inventoryMovementData };
    newObject.data[index].isSelected = e.target.checked;
    setInventoryMovementData(newObject);

    const selectedRows = newObject.data.filter(row => row.isSelected);
  };


  const handlenewRecordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trim = e.target.value.trim()
    setNewRecord(trim);
  };

  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: t('Debit') },
    { id: 1, name: t('Responsible') },
    { id: 2, name: t('Campus') },
    { id: 3, name: t('Location') },
    { id: 4, name: t('InventoryStatus') },
    { id: 5, name: t('Department') }
  ];


  const columns: IAltisDataGridColumns[] = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e)}
          checked={
            inventoryMovementData?.data?.filter(
              (row) =>
                row.isSelected === false || row.isSelected === null || row.isSelected === undefined
            ).length === 0
          }
        />
      ),
      key: '',
      placeHolder: '',
      inputType: 'custom',
      width: 80,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e, index)}
          checked={record.isSelected}>
        </Checkbox>

      ),
    },
    {
      title: t('date'),
      key: 'updatedDate',
      placeHolder: t('date'),
      inputType: 'noFilter'
  },
  { 
    title: t('inventoryName'), 
    key: 'inventoryName', 
    placeHolder: t('inventoryName'), 
    inputType: 'noFilter',
    titleAlign:'center',
    align:'left'
  },
  { 
    title: t('barcode'), 
    key: 'barcode', 
    placeHolder: t('barcode'), 
    inputType: 'noFilter'
  },
  { 
    title: <div style={{ textAlign: 'center' }}>{t('movementType')}</div>, 
    key: t('inventoryTracking'), 
    placeHolder: t('movementType'), 
    inputType: 'operations', 
    dropdownItem:dropdownItemType,
    width: 200,
    render: (value) => {
        return <div style={{ textAlign: 'center' }}>{t(value)}</div>;
      }
    },
    {
      title: t('oldRecord'),
      key: 'oldRecord',
      placeHolder: t('oldRecord'),
      inputType: 'noFilter'
    },
    {
      title: t('newRecord'),
      key: 'newRecord',
      placeHolder: t('newRecord'),
      inputType: 'noFilter'
    },
  ];


  const InventoryReportFilter = (e?) => {

    const body = {
      category: e?.category,
      campus: e?.campus,
      type: e?.type
    };

    HTTPService.POST(`Report/postfilters`, body).then((response) => {
      if (response.status === 200) {
        setFilterOptions(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  // const DeleteInventory = (id) => {
  //   HTTPService.DELETE(`/Inventory/deleteInventory?Id=${id}`).then((response) => {
  //     if (response.status === 200) {
  //       toast.success(t(response.data));
  //       InventoryFilter();
  //     } else {
  //       toast.error(t(response.data));
  //     }
  //   });
  // };

  const InventoryFilter = (e?) => {


    const body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      barcode: e?.barcode,
      inventoryName: e?.inventoryName,
      oldRecord: e?.oldRecord,
      newRecord: e?.newRecord,
      updatedDate: e?.startDate ? moment(e.startDate).toISOString() : undefined,
      inventoryTracking: e?.inventoryTracking
    };


    HTTPService.POST(`/Report/filtersTracking`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        setInventoryMovementData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const handleSearch = () => {
    const filterData = {
      ...filters,
      pageSize: 10,
      page: 1,
      inventoryName: inputValue,
      barcode: barcodeValue,
      oldRecord: oldRecordValue,
      newRecord: newRecord,
      inventoryTracking: selectedValues
    };

    InventoryFilter(filterData);
  };

  const handleDateChange = (dates) => {
    if (!dates) {
      setFilters((prev) => ({ ...prev, startDate: null, endDate: null }));
      setCreateDate({ startDate: null, endDate: null });
      return;
    }

    const [startDate, endDate] = dates;
    setFilters((prev) => ({ ...prev, startDate, endDate }));
    setCreateDate({ startDate, endDate });
  };


  const clearFilters = () => {
    const initialFilters = {
      category: [],
      status: [],
      campus: [],
      locations: [],
      brand: [],
      supplier: [],
      type: [],
      department: []
    };

    setInputValue('');
    setBarcodeValue('');
    setOldRecordValue('');
    setSelectedValues([]);
    setNewRecord('');
    setCreateDate({ startDate: null, endDate: null });
    setFilters((prev) => ({ ...prev, startDate: null, endDate: null }));

    InventoryFilter({
      pageSize: 10,
      page: 1,
      updateDate: null,
      startDate: null,
      endDate: null
    });
  };

  const excelDownload = () => {
    const selectedRows = inventoryMovementData.data.filter(row => row.isSelected);

  if (selectedRows.length === 0) {
      toast.error(t('NoRows'));
      return;
    }


    const selectedData = selectedRows.map(row => ({
      inventoryName: row.inventoryName,
      barcode: row.barcode,
      inventoryTracking: row.inventoryTracking,
      newRecord: row.newRecord,
      oldRecord: row.oldRecord,
      updatedDate: row.updatedDate
    }));


    HTTPService.POSTblobExcel('/Report/ExcelDownloadMovement', selectedData)
      .then(() => {
        toast.success(t('excelSuccess'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('error'));
      });
  };

  const pdfDownload = () => {
    const selectedRows = inventoryMovementData.data.filter(row => row.isSelected);

  if (selectedRows.length === 0) {
      toast.error(t('NoRows'));
      return;
    }


    const selectedData = selectedRows.map(row => ({
      inventoryName: row.inventoryName,
      barcode: row.barcode,
      inventoryTracking: row.inventoryTracking,
      newRecord: row.newRecord,
      oldRecord: row.oldRecord,
      updatedDate: row.updatedDate
    }));


    HTTPService.POSTblob('/Report/PdfDownloadMovement', selectedData)
      .then(() => {
        toast.success(t('pdfSuccess'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('error'));
      });
  };


  return (
    <>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col xs={24} sm={8} md={6} lg={4} xl={4} style={{ paddingLeft: '8px' }}>
        <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '5px', 
        padding: '5px 10px', 
        border: '1px solid #d9d9d9',
        borderRadius: '5px',
        backgroundColor: 'white',
        marginTop: '40px',
      }}>
      <span style={{ 
          flexGrow: 1,
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '14px', 
          color: '#333'
        }}>
        {t('noFilter')}
      </span>
      <CloseOutlined
        style={{ cursor: 'pointer', color: 'black', fontSize: '16px' }} onClick={clearFilters}
      />
    </div>
          <Collapse style={{ 
            width: '100%', 
            maxWidth: '220px', 
            marginTop: '20px', 
            border: 'none' 
          }}>
            <Collapse.Panel header={t('inventoryName')} key="2">

              <div>
                <Input
                  name='inventoryName'
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder={t('inventoryName')}
                />
              </div>

            </Collapse.Panel>

            <Collapse.Panel header={t('barcode')} key="3">

              <div>
                <Input
                  name='barcode'
                  type="text"
                  value={barcodeValue}
                  onChange={handleBarcodeChange}
                  placeholder={t('barcode')}
                />
              </div>
            </Collapse.Panel>

            <Collapse.Panel header={t('movementInventory')} key="4">
              <div>
                <Checkbox
                  value={0}
                  checked={selectedValues.includes(0)}
                  onChange={() => handleCheckboxChange(0)}
                  style={{ marginLeft: '8px' }}
                >
                  {t('Debit')}
                </Checkbox>
                <Checkbox
                  value={1}
                  checked={selectedValues.includes(1)}
                  onChange={() => handleCheckboxChange(1)}
                >
                  {t('Responsible')}
                </Checkbox>
                <Checkbox
                  value={2}
                  checked={selectedValues.includes(2)}
                  onChange={() => handleCheckboxChange(2)}
                >
                  {t('Campus')}
                </Checkbox>
                <Checkbox
                  value={3}
                  checked={selectedValues.includes(3)}
                  onChange={() => handleCheckboxChange(3)}
                >
                  {t('Location')}
                </Checkbox>
                <Checkbox
                  value={4}
                  checked={selectedValues.includes(4)}
                  onChange={() => handleCheckboxChange(4)}
                >
                  {t('InventoryStatus')}
                </Checkbox>
                <Checkbox
                  value={5}
                  checked={selectedValues.includes(5)}
                  onChange={() => handleCheckboxChange(5)}
                >
                  {t('Department')}
                </Checkbox>
              </div>
            </Collapse.Panel>

            <Collapse.Panel header={t('oldRecord')} key="5">
              <div>
                <Input
                  name='oldRecord'
                  type="text"
                  value={oldRecordValue}
                  onChange={handleRecordChange}
                  placeholder={t('oldRecord')}
                />
              </div>
            </Collapse.Panel>


            <Collapse.Panel header={t('newRecord')} key="6">
              <div>
                <Input
                  name='newRecord'
                  type="text"
                  value={newRecord}
                  onChange={handlenewRecordChange}
                  placeholder={t('newRecord')}
                />
              </div>
            </Collapse.Panel>

            <Panel header={t('date')} key="10">
              <DatePicker.RangePicker onChange={handleDateChange} format={'DD/MM/YYYY'} style={{ width: '100%' }} />
            </Panel>
          </Collapse>
          <Button type="primary" style={{ marginTop: '10px', width: '100%' }} onClick={handleSearch}>
            {t('search')}
          </Button>
        </Col>

        <Col xs={24} sm={16} md={18} lg={20} xl={20} style={{ marginTop: '10px', paddingLeft: '8px' }}>
          {/* <Button type="primary" style={{marginTop:'-40px',width:'200px', marginRight: '10px'}}>
            Excel
          </Button>
          <Button type="primary" style={{marginTop:'-40px',width:'200px'}}>
            PDF
          </Button> */}
          <Button type="primary" onClick={excelDownload} style={{ marginTop: '-40px', width: '200px', marginRight: '10px' }}>
            {t('Excel')}
          </Button>
          <Button type="primary" onClick={pdfDownload} style={{ marginTop: '-40px', width: '200px' }}>
            {t('PDF')}
          </Button>

          <AltisDataGrid 
          data={inventoryMovementData.data} 
          total={inventoryMovementData.count} 
          columns={columns} 
          onChange={InventoryFilter}            
          />
        </Col>
      </Row>
    </>
  );



};

export default InventoryMovementManagement;
