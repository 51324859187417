import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Avatar, Button, Popover } from 'antd';
import { useDispatch } from 'react-redux';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { CloudFilled, UserOutlined, MessageFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Flag from 'react-flagkit';
import './Actions.scss';
import { removeUser } from '../../../redux/user/actions';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { messaging, generateToken } from '../../../firebase/firebase'; // Firebase import edilmiş
import { onMessage } from 'firebase/messaging';
import logo from '../../../assets/img/TagAndTrace_logo.png';
import HTTPService from '../../../utils/makerequest';
import { userInfo } from 'os';


const Actions: React.FunctionComponent<any> = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [isConnected, setIsConnected] = useState(false);
    const [user, setUser] = useState("");
    const [messages, setMessages] = useState<any[]>([]);
    const [isConnecting, setIsConnecting] = useState(true);
    const loader = new StateLoader();

    // const openDatabase = () => {
    //     const request = window.indexedDB.open('MessagesDB', 1);
    //     request.onupgradeneeded = (event) => {
    //         const db = (event.target as IDBRequest).result;
    //         if (!db.objectStoreNames.contains('messages')) {
    //             db.createObjectStore('messages', { keyPath: 'id', autoIncrement: true });
    //         }
    //     };
    //     return request;
    // };

    // const addMessageToDB = (message: { title: string, body: string }) => {
    //     const request = openDatabase();
    //     request.onsuccess = (event) => {
    //         const db = (event.target as IDBRequest).result;
    //         const transaction = db.transaction('messages', 'readwrite');
    //         const store = transaction.objectStore('messages');
    //         store.add(message);
    //     };
    // };

    // const getMessagesFromDB = (callback: (messages: any[]) => void) => {
    //     const request = openDatabase();
    //     request.onsuccess = (event) => {
    //         const db = (event.target as IDBRequest).result;
    //         const transaction = db.transaction('messages', 'readonly');
    //         const store = transaction.objectStore('messages');
    //         const getAllRequest = store.getAll();
    //         getAllRequest.onsuccess = () => {
    //             callback(getAllRequest.result);
    //         };
    //     };
    // };

    const fetchMessages = () => {
        HTTPService.GET(`/Dashboard/getFirebaseMessage`).then(
          (response) => {
            try {
                if (response.status === 200) {
                    console.log(response.data)
                    console.log(response.data.map(x=>x.message))
                    setMessages(response.data.map(x=>x.message))
                 } else {
                     console.error('Failed to fetch messages');
                 }
            } catch (error) {
                console.error('Error fetching messages:', error.toString());
            }
           
          }
        );
      };
    

    useEffect(() => {
        const userInfo: IAuthentication = loader.loadAuthState();
        setUser(`${userInfo?.firstname} ${userInfo?.lastname} - ${UserRole[userInfo?.role]}`);
        // const storedMessages = JSON.parse(sessionStorage.getItem('messages')) || [];
        // setMessages(storedMessages);

        // getMessagesFromDB((messages) => {
        //     setMessages(messages);
        // });

        fetchMessages();
    
        // SignalR bağlantısını başlatma
        // const connection = new HubConnectionBuilder()
        // .withUrl("https://localhost:7084/tagAndTraceInventoryHub").withAutomaticReconnect()
        // .build();
        
        //connection.start()
        // .then(() => {
        //     setIsConnected(true);
        //     setIsConnecting(false);
        //     console.log("SignalR connected");
        //     connection.on("ReceiveNotification", (title ,body) => {
        //         console.log('Received notification:', title, body);
        //         setMessages(prevMessages => [...prevMessages, `${title}: ${body}`]);
        //     });
        // })
        // .catch((error) => {
        //     console.error("SignalR connection error: ", error);
        // });
        firebaseInit();
        
        // return () => {
        //     if (connection) {
        //         connection.stop().then(() => {
        //             setIsConnected(false);
        //             console.log("SignalR disconnected");
        //         });
        //     }
        // };
    }, []);

    const firebaseInit = async () => {
        await onMessage(messaging, (payload) => {
            const { notification } = payload;
            const message = { title: notification.title, body: notification.body };
            // addMessageToDB(message);
            setMessages((prevMessages) => [...prevMessages, message]);
            if (Notification.permission === 'granted') {
                new Notification(notification.title, { body: notification.body });
            }
        });
    };
    const logout = () => {
        dispatch(removeUser());
        loader.removeAuthLoader();
        window.location.replace('/public/sign-in/');
    };

    const resetPassword = () => {
        window.location.replace('/admin/reset-password');
    };

    const changeEmail = () => {
        window.location.replace('/admin/change-email');
    };

    const changeLanguage = (lng: string) => {
        if (i18n.changeLanguage) {
            i18n.changeLanguage(lng);
            loader.setLanguage(lng);
        } else {
            console.error('i18n.changeLanguage is not a function');
        }
    };

    const languageMenu = (
        <Menu>
            <Menu.Item key='1' onClick={() => changeLanguage('tr')}>
                <Flag country="TR" style={{ marginRight: '8px' }} />
                {t('turkish')}
            </Menu.Item>
            <Menu.Item key='2' onClick={() => changeLanguage('en')}>
                <Flag country="US" style={{ marginRight: '8px' }} />
                {t('english')}
            </Menu.Item>
        </Menu>
    );

    const accountMenu = (
        <Menu style={{ minWidth: '200px' }}>
            <Menu.SubMenu key='profile' title={t('profile')} style={{ marginLeft: 7 }}>
                <Menu.Item key='reset-password' onClick={resetPassword}>
                    {t('reset_password')}
                </Menu.Item>
                <Menu.Item key='change-email' onClick={changeEmail}>
                    {t('change_email')}
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key='logout'>
                <span className='d-flex justify-content-between align-items-center'>
                    <a className='d-flex w-100' onClick={logout}>
                        {t('log_out')}
                    </a>
                    <span className='d-flex align-items-center icofont-logout' />
                </span>
            </Menu.Item>
        </Menu>
    );

    const buttonText = i18n.language === 'tr' ? <Flag country="TR" /> : <Flag country="US" />;

    const messageContent = (
        <div className='message-popover'>
            <ul>
                {messages.length > 0 ? (
                    messages.map((msg, index) => {
                         console.log("Message:", msg); 
                        return (
                            <li key={index}>
                                <strong>{msg}</strong>
                            </li>
                        );
                    })
                ) : (
                    <li>{t('no_messages')}</li>
                )}
            </ul>
        </div>
    );
    
    const messageIconStyle = {
        fontSize: '20px',
        cursor: 'pointer',
        marginLeft:'5px'
    };

    return (
        <div className='actions'>
            <div>{user}</div>
            <Dropdown overlay={accountMenu} trigger={['click']}>
                <div className='item'>
                    <UserOutlined className='mr-1' />
                    <span className='icofont-simple-down' />
                </div>
            </Dropdown>
            
            <Dropdown overlay={languageMenu} trigger={['click']}>
                <Button className='language-selector'>
                    {buttonText} <span className='icofont-simple-down' />
                </Button>
            </Dropdown>

            <Popover content={messageContent} title={<strong>{t('countOrder')}</strong>} trigger="click">
                <Button className='message-icon' style={messageIconStyle}>
                    <MessageFilled style={{ fontSize: '20px'}} />
                </Button>
            </Popover>
        </div>
    );
};

export default Actions;
