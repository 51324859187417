import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, SelectProps, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next';
import { ILocationData } from "../../../interfaces/ILocationData";
import { ICampusData } from "../../../interfaces/ICampusData";
import { UserOutlined } from "@ant-design/icons";
import { ICountingData } from "../../../interfaces/ICountingData";
import moment from "moment";
import '../CountingManagement/Counting.css'

const UpdateCounting = () => {
    const { countingJobId } = useParams<any>();
    const [form] = Form.useForm(); // Create form reference
    const [countingData, setCountingData] = useState<ICountingData>({});
    const [locationData, setLocationData] = useState<ILocationData[]>([]);
    const [campusData, setCampusData] = useState<ICampusData[]>([]);
    const [companyData, setCompanyData] = useState<ICompanyData[]>([]);
    const [userData, setUserData] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);
    const [selectedCampus, setSelectedCampus] = useState<string | undefined>(undefined);
    const [selectedResponsible, setSelectedResponsible] = useState<number[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number[]>([]);
    const loader = new StateLoader();
    const userInfo: IAuthentication = loader.loadAuthState();
    const { t } = useTranslation();

    useEffect(() => {
        fetchCompanyData();
        if (countingJobId) {
            getCountingData(countingJobId);
        }
    }, [countingJobId]);

    useEffect(() => {
        if (selectedCompany) {
            fetchCampusesByCompany(selectedCompany);
            fetchUser(selectedCompany);
        } else {
            setCampusData([]);
            setUserData([]);
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (selectedCampus) {
            fetchLocationByCampus(selectedCampus);
        } else {
            setLocationData([]);
        }
    }, [selectedCampus]);

    const fetchUser = async (companyId: number) => {
        try {
            const result = await HTTPService.GETUserNames(companyId);
            setUserData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
        return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
    };

    const fetchCompanyData = async () => {
        try {
            const result = await HTTPService.GETCompanyNames();
            setCompanyData(result);
        } catch (error) {
            console.error("Error fetching company data:", error);
        }
    };

    const getCountingData = async (countingJobId: number) => {
        try {
            const res = await HTTPService.GET(`/CountingJob/ById?countingJobId=${countingJobId}`);
            if (res.status === 200 && res.data) {
                setCountingData(res.data);
                setSelectedCampus(res.data.campusId);
                setSelectedLocation(res.data.countingJobLocationTBL.map(location => location?.locationTBL?.locationId));
                setSelectedResponsible(res.data.countingJobResponsibleTBL.map(responsible =>responsible?.responsibleUserTBL.userId))
                setSelectedCompany(res.data.companyId);
                form.setFieldsValue({
                    companyId: res.data.companyId,
                    campusId: res.data.campusId,
                    locationId: res.data.countingJobLocationTBL.map(location => location?.locationTBL?.locationId),
                    responsibleUserId: res.data.countingJobResponsibleTBL.map(responsible=>responsible?.responsibleUserTBL?.userId),
                    countingJobName: res.data.countingJobName,
                    startDate: moment(res.data.startDate),
                    finishDate: moment(res.data.finishDate),
                });
                console.log(res.data.countingJobLocationTBL.map(location => location?.locationTBL?.locationId))
                console.log(res.data.countingJobResponsibleTBL.map(responsible=>responsible?.responsibleUserTBL.userId))
                fetchCampusesByCompany(res.data.companyId);
                fetchUser(res.data.companyId);
            } else {
                toast.error(res.error?.message || t('error_fetching_data'));
            }
        } catch (error) {
            console.error("Error fetching counting data:", error);
            toast.error(t('error_fetching_data'));
        }
    };

    const fetchCampusesByCompany = async (companyId: number) => {
        try {
            const result = await HTTPService.GETCampusByCompany(companyId);
            setCampusData(result);
        } catch (error) {
            console.error("Error fetching campuses by company:", error);
            setCampusData([]);
        }
    };

    const fetchLocationByCampus = async (campusId: string) => {
        try {
            const result = await HTTPService.GETAllLocationNameByCampus(campusId);
            setLocationData(result);
        } catch (error) {
            console.error("Error fetching locations by campus:", error);
            setLocationData([]);
        }
    };

    const updateCounting = async (formData) => {
        try {
            const adjustedStartDate = formData.startDate ? new Date(formData.startDate) : null;
            if (adjustedStartDate) {
                adjustedStartDate.setHours(adjustedStartDate.getHours() + 3);
            }

            const adjustedFinishDate = formData.finishDate ? new Date(formData.finishDate) : null;
            if (adjustedFinishDate) {
                adjustedFinishDate.setHours(adjustedFinishDate.getHours() + 3);
            }


            const countingJobTBL = {
                countingJobId: countingJobId,
                //locationName: formData.locationName || countingData.locationName,
                companyId: selectedCompany || countingData.companyId,
                //campusId: selectedCampus || countingData.campusId,
                locationIds: selectedLocation.length,
                countingJobName: formData.countingJobName || countingData.countingJobName,
                responsibleUserIds: selectedResponsible.length,
                startDate: adjustedStartDate.toISOString() || countingData.startDate,
                finishDate: adjustedFinishDate.toISOString() || countingData.finishDate,
            };

            const dataToSend = {
                countingJobTBL: countingJobTBL,
                locationIds: selectedLocation,
                responsibleUserIds:selectedResponsible
            };

            const res = await HTTPService.PUT(`/CountingJob/updateCounting`, dataToSend);
            if (res.status === 200) {
                toast.success(t(res.data));
                window.location.replace("/admin/counting-management");
            } else if (res.status === 450) {
                toast.error(t(res.data));
            } else {
                toast.error(t(res.data));
            }
        } catch (error) {
            toast.error(t('counting_update_failed'));
        }
    };

    const handleCancel = () => {
        window.location.replace("/admin/counting-management/");
    };

    const handleLocationChange = (values: number[]) => {
        setSelectedLocation(values);
    };

    const handleResponsibleChange = (values: number[]) => {
        setSelectedResponsible(values.length === 0 ? [] : values);
    };


    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={updateCounting}
        >
            <Card style={{ padding: "50px" }}>
                <h3>{t('Update Counting')}</h3>
                {userInfo?.role === UserRole.AltisAdmin && (
                    <Form.Item
                        name='companyId'
                        label={t('Select company')}
                        rules={[
                            {
                                required: true,
                                message: t('please_select_a_company'),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder={t('company_name')}
                            style={{ marginBottom: '6px' }}
                            value={selectedCompany}
                            onChange={(value) => {
                                setSelectedCompany(value);
                                setSelectedCampus(undefined);
                                setSelectedLocation(undefined);
                                setSelectedResponsible(undefined);
                                form.setFieldsValue({
                                    campusId: undefined,
                                    locationId: undefined,
                                    responsibleUserId: undefined,
                                    countingJobName: undefined,
                                    startDate: null,
                                    finishDate: null,
                                });
                                fetchCampusesByCompany(value);
                                fetchUser(value);
                            }}
                        >
                            {companyData.map((company) => (
                                <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}

                <Form.Item
                    name='campusId'
                    label={t('campus')}
                    rules={[{ required: true, message: t('select_campus') }]}
                >
                    <Select
                        placeholder={t('campus')}
                        value={selectedCampus}
                        filterOption={filterOption}
                        onChange={(value) => {
                            setSelectedCampus(value);
                            setSelectedLocation([]);
                            form.setFieldsValue({
                                locationId: [],
                            });
                            fetchLocationByCampus(value);
                        }}
                    >
                        {campusData.map(row => (
                            <Select.Option key={row.campusId} value={row.campusId}>
                                {row.campusName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name='locationId'
                    label={t('location')}
                    rules={[{ required: true, message: t('select_location') }]}
                >
                    <Select
                        placeholder={t('location')}
                        mode="multiple"
                        allowClear={false}
                        filterOption={filterOption}
                        value={selectedLocation}
                        onChange={handleLocationChange}
                        dropdownStyle={{ maxHeight: '200px', overflowY: 'auto' }}
                    >
                        {locationData.map(row => (
                            <Select.Option key={row.locationId} value={row.locationId}>
                                {row.locationName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                
                <Form.Item
                    name='responsibleUserId'
                    label={
                        <Tooltip title={t('select_debit_count')}>
                            {t('Responsible Name')}
                        </Tooltip>
                    }
                    rules={[{ required: false, message: t('responsible_name') }]}

                >
                    <Select
                        placeholder={t('Responsible Name')}
                        mode="multiple"
                        allowClear={false}
                        filterOption={filterOption}
                        value={selectedResponsible}
                        onChange={handleResponsibleChange}
                        dropdownStyle={{ maxHeight: '200px', overflowY: 'auto' }}
                    >
                        {userData.map(row => (
                            <Select.Option key={row.userId} value={row.userId}>
                                {row.firstName + " " + row.lastName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="countingJobName"
                    label={t('Counting Job Name')}
                    rules={[{ required: true, message: t('inputCounting'), whitespace: true }]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        style={{ borderRadius: 0 }}
                        placeholder={t('Counting Job Name')}
                    />
                </Form.Item>

                <Form.Item
                    label={t("Counting Start Date")}
                    name="startDate"
                    rules={[{ required: true, message: t('pleaseCounting') }]}
                >
                    <DatePicker
                        style={{ width: '100%', borderRadius: 0 }}
                        placeholder={t("Counting Start Date")}
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm' }}
                    />
                </Form.Item>

                <Form.Item
                    label={t('Counting Finish Date')}
                    name='finishDate'
                    rules={[{ required: true, message: t('pleaseCountingFinish') }]}
                >
                    <DatePicker
                        style={{ width: '100%', borderRadius: 0 }}
                        placeholder={t('Counting Finish Date')}
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm' }}
                    />
                </Form.Item>

                <Row gutter={[8, 8]} justify="end" align="middle">
                    <Col xs={12} sm={4}>
                        <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                            {t('cancel')}
                        </Button>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                            {t('update')}
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Form>
    );
};

export default UpdateCounting;
