import * as signalR from "@microsoft/signalr";
import { API_URL } from "./Constants";

class SignalRHub {
    onReconnecting(arg0) {
      throw new Error('Method not implemented.');
    }
    constructor() {
        this.hubConnection = null;
        this.isConnected = false;
    }

     connect() {
         const url = "https://localhost:7084/tagAndTraceInventoryHub";
        //  console.log(url)
         this.hubConnection = new signalR.HubConnectionBuilder()
       .withUrl(url, {  
         skipNegotiation: true,
         transport: signalR.HttpTransportType.WebSockets
         }) 
         .withAutomaticReconnect()
       .build();
     this.hubConnection.start()
       .then(() => console.log("SignalR hub connected"))
       .catch((err) => console.error("Error connecting to SignalR hub: ", err));
       this.hubConnection.on("connected", () => {
        //  console.log("SignalR hub reconnected");
       });
  
       this.hubConnection.onclose(() => {
        //  console.log("SignalR hub disconnected");
       });

       return this.hubConnection;
     }

    on(eventName, callback) {
        if (this.hubConnection) {
            this.hubConnection.on(eventName, callback);
        }
    }

    off(eventName, callback) {
        if (this.hubConnection) {
            this.hubConnection.off(eventName, callback);
        }
    }

    invoke(methodName, ...args) {
        if (this.hubConnection) {
            return this.hubConnection.invoke(methodName, ...args);
        }
    }
}

export default SignalRHub;