import React, { useEffect, useState } from "react";
import { Button, Card, Col, ConfigProvider, DatePicker, Form, Input, Row, Select, SelectProps, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next';
import { ICampusData } from "../../../interfaces/ICampusData";
import { ILocationData } from "../../../interfaces/ILocationData";
import '../CountingManagement/Counting.css'

const AddCounting: React.FunctionComponent<IPageProps> = () => {
    const { t } = useTranslation();
    const [campusData, setCampusData] = useState<ICampusData[]>([]);
    const [locationData, setLocationData] = useState<ILocationData[]>([]);
    const [companyData, setCompanyData] = useState<ICompanyData[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);
    const [selectedCampus, setSelectedCampus] = useState<string | undefined>(undefined);
    const [selectedLocation, setSelectedLocation] = useState<number[]>([]);
    const [selectedResponsible, setSelectedResponsible] = useState<number[]>([]);
    const [userData, setUserData] = useState([]);
    const loader = new StateLoader();
    const userInfo: IAuthentication = loader.loadAuthState();

    const [form] = Form.useForm();

    useEffect(() => {
        fetchCompanyData();
    }, []);

    useEffect(() => {
        if (selectedCompany || userInfo.companyId) {
            fetchCampusesByCompany(selectedCompany || userInfo.companyId);
            fetchUser(selectedCompany || userInfo.companyId);
        } else {
            //setCampusData([]);
            setUserData([]);
        }
    }, [selectedCompany]);

    const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
        return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
    };

    const fetchUser = async (companyId: number) => {
        try {
            const result = await HTTPService.GETUserNames(companyId);
            setUserData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchCompanyData = async () => {
        try {
            const result = await HTTPService.GETCompanyNames();
            setCompanyData(result);
        } catch (error) {
            console.error("Şirket verilerini alırken bir hata oluştu:", error);
        }
    };

    const fetchCampusesByCompany = async (companyId: number) => {
        try {
            const result = await HTTPService.GETCampusByCompany(companyId);
            setCampusData(result);
        } catch (error) {
            console.error("Kampüs verilerini alırken bir hata oluştu:", error);
            setCampusData([]);
        }
    };

    const fetchAllLocationNameByCampus = async (campusId: string) => {
        try {
            const result = await HTTPService.GETAllLocationNameByCampus(campusId);
            setLocationData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const handleCampusChange = (campusId: string) => {
        setSelectedCampus(campusId);
        setSelectedLocation([]);
        form.setFieldsValue({
            locationId: [],
        });
        fetchAllLocationNameByCampus(campusId);
    };

    const handleCompanyChange = (value: number) => {
        setSelectedCompany(value);
        setSelectedCampus('');
        setSelectedLocation([]);
        setSelectedResponsible([]);
        form.setFieldsValue({
            campusId: '',
            locationId: [],
            responsibleUserId: [],
            countingJobName: '',
            startDate: null,
            finishDate: null,
        });
        fetchCampusesByCompany(value);
        fetchUser(value);
    };

    const handleCancel = () => {
        window.location.replace("/admin/counting-management/");
    };

    const CountingRegister = (values) => {
        let companyIdToUse;
        if (userInfo?.role === UserRole.Admin) {
            companyIdToUse = userInfo?.companyId;
        } else {
            companyIdToUse = values.selectedCompany;
        }
        const adjustedStartDate = new Date(values.startDate);
        adjustedStartDate.setHours(adjustedStartDate.getHours() + 3);

        const adjustedFinishDate = new Date(values.finishDate);
        adjustedFinishDate.setHours(adjustedFinishDate.getHours() + 3);

        const responsibleUserIdsToSend = selectedResponsible.length === 0 ? [] : selectedResponsible;

        const countingJobTBL = {
            locationName: values.locationName,
            companyId: companyIdToUse,
            campusId: selectedCampus,
            locationIds: selectedLocation.length,
            countingJobName: values.countingJobName,
            responsibleUserIds: responsibleUserIdsToSend,
            startDate: adjustedStartDate.toISOString(),
            finishDate: adjustedFinishDate.toISOString(),
        };

        const dataToSend = {
            countingJobTBL: countingJobTBL,
            locationIds: selectedLocation,
            responsibleUserIds:selectedResponsible,
        };

        // console.log(dataToSend)

        HTTPService.POST(`/CountingJob/registerCounting`, dataToSend)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(t('countjobSuccess'));
                    window.location.replace("/admin/counting-management");
                } else if (response.status === 450) {
                    toast.error(t(response.data));
                } else if (response.status === 400) {
                    toast.error(t(response.data));
                }
                else {
                    toast.error(t(response.data));
                }
            })
            .catch((error) => {
                toast.error("Count record failed.");
            });
    };

    const handleLocationChange = (values: number[]) => {
        setSelectedLocation(values);
    };

    const handleResponsibleChange = (values: number[]) => {
        setSelectedResponsible(values.length === 0 ? [] : values);
    };

    return (
        <Card style={{ padding: "50px" }}>
            <h3>{t('addCounting')}</h3>
            <Form
                form={form} // Form referansını ekleyin
                onFinish={CountingRegister}
                layout="vertical"
            >
                {userInfo?.role === UserRole.AltisAdmin ? (
                    <Form.Item
                        name='selectedCompany'
                        label={t('Select company')}
                        rules={[{ required: true, message: t('please_select_a_company') }]}
                    >
                        <Select
                            showSearch
                            placeholder={t('company_name')}
                            style={{ marginBottom: '6px' }}
                            onChange={handleCompanyChange}
                            filterOption={filterOption}
                        >
                            {companyData.map((company) => (
                                <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : null}
                <Form.Item
                    name='campusId'
                    label={t('campus')}
                    rules={[{ required: true, message: t('select_campus') }]}
                >
                    <Select
                        placeholder={t('campus')}
                        onChange={handleCampusChange}
                        filterOption={filterOption}
                        value={selectedCampus}

                    >
                        {campusData.map(row => (
                            <Select.Option key={row.campusId} value={row.campusId}>
                                {row.campusName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name='locationId'
                    label={t('location')}
                    rules={[{ required: true, message: t('select_location') }]}

                >
                    <Select
                        placeholder={t('location')}
                        mode="multiple"
                        allowClear={false}
                        filterOption={filterOption}
                        value={selectedLocation}
                        onChange={handleLocationChange}
                        dropdownStyle={{ maxHeight: '200px', overflowY: 'auto' }}
                    >
                        {locationData.map(row => (
                            <Select.Option key={row.locationId} value={row.locationId}>
                                {row.locationName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>


                <Form.Item
                    name='responsibleUserId'
                    label={
                        <Tooltip title={t('select_debit_count')}>
                            {t('Responsible Name')}
                        </Tooltip>
                    }
                    rules={[{ required: false, message: t('responsible_name') }]}

                >
                    <Select
                        placeholder={t('Responsible Name')}
                        mode="multiple"
                        allowClear={false}
                        filterOption={filterOption}
                        value={selectedResponsible}
                        onChange={handleResponsibleChange}
                        dropdownStyle={{ maxHeight: '200px', overflowY: 'auto' }}
                    >
                        {userData.map(row => (
                            <Select.Option key={row.userId} value={row.userId}>
                                {row.firstName + " " + row.lastName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>


                <Form.Item
                    name="countingJobName"
                    label={t('Counting Job Name')}
                    rules={[{ required: true, message: t('inputCounting'), whitespace: true }]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        style={{ borderRadius: 0 }}
                        placeholder={t('Counting Job Name')}
                    />
                </Form.Item>

                    <Form.Item
                        name='startDate'
                        label={t('Counting Start Date')}
                        rules={[
                            {
                                required: true,
                                message: t('pleaseCounting'),
                            },
                        ]}
                    >
                        <DatePicker
                            style={{ width: '100%', borderRadius: 0 }}
                            placeholder={t('Counting Start Date')}
                            format="DD.MM.YYYY HH:mm"
                            showTime={{ format: 'HH:mm' }}
                        />
                    </Form.Item>

                    <Form.Item
                        name='finishDate'
                        label={t('Counting Finish Date')}
                        rules={[
                            {
                                required: true,
                                message: t('pleaseCountingFinish'),
                            },
                        ]}
                    >
                        <DatePicker
                            style={{ width: '100%', borderRadius: 0 }}
                            placeholder={t('Counting Finish Date')}
                            format="DD.MM.YYYY HH:mm"
                            showTime={{ format: 'HH:mm' }}
                        />
                    </Form.Item>

                <Form.Item>
                    <Row gutter={[8, 8]} justify="end" align="middle">
                        <Col xs={12} sm={4}>
                            <Button
                                block
                                onClick={handleCancel}
                                type="primary"
                                style={{ borderRadius: 0 }}
                            >
                                {t('cancel')}
                            </Button>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Button
                                block
                                htmlType="submit"
                                type="primary"
                                style={{ borderRadius: 0 }}
                            >
                                {t('save')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default AddCounting;