// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyBzp-Eas36bWVkEoyxLTmk2Gn9in3wkYlA",
  authDomain: "tagandtrace-43338.firebaseapp.com",
  projectId: "tagandtrace-43338",
  storageBucket: "tagandtrace-43338.firebasestorage.app",
  messagingSenderId: "784946155830",
  appId: "1:784946155830:web:a293085246dfcda250a397",
  measurementId: "G-9XBJV8E0Y1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


const generateToken = async () => {
  try{

    const permission = await Notification.requestPermission();
    // console.log(permission);
  
    if (permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey: "BAZ_fh1jpb3bFh9LQBoBTda6IrTYPuR3kVxSIjL0SsekGujSbCVCCaEt8mzoJIOOwyX3oNo7SQ1FszRtCCih5Ms" 
        });
        // console.log(token);
        return token;
      } catch (error) {
        // console.error("Error getting token:", error);
      }
    } else {
      // console.log("Notification permission denied.");
    }
  }
  catch(exception){
    console.error(exception);
    generateToken();
  }
  };
  
  export { messaging, generateToken };
