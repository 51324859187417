//region Layouts
import PublicLayout from './layouts/Public/Public';
import AdminLayout from './layouts/Admin/Admin';
//endregion

//region Default Routes
import PageDashboard from './pages/home/Dashboard';
import PageUserSettings from './pages/UserSettings/UserSettings';

//endregion

//region Public Routes
import PageSignIn from './pages/signIn/SignIn';
import AddUser from './pages/UserSettings/AddUser';
import UpdateUser from './pages/UserSettings/UpdateUser';
import PasswordChange from './pages/home/Account/PasswordChange';
import AddCustomer from './pages/home/CompanyManagement/AddCustomer';
import CustomerManagement from './pages/home/CompanyManagement/CustomerManagement';
import CompanyManagement from './pages/home/CompanyManagement/CompanyManagement';
import UpdateCompany from './pages/home/CompanyManagement/UpdateCompany';
import ForgotPassword from './pages/signIn/ForgotPassword';
import EmailChange from './pages/home/Account/EmailChange';
import TemplatesPage from './pages/home/Templates/Templates';
import AddTemplates from './pages/home/Templates/AddTemplates';
import UpdateTemplates from './pages/home/Templates/UpdateTemplates';
import PrintsJobsExcel from './pages/home/TagsPage/PrintsJobs(Excel)';
import PrintsJobsAPI from './pages/home/TagsPage/PrintsJobs(API)';
import PrintsJobsDetailsExcel from './pages/home/TagsPage/PrintsJobsDetailsExcel';
import PrintsJobsDetailsAPI from './pages/home/TagsPage/PrintsJobsDetailsAPI';
import CategoryManagement from './pages/home/CategoryManagement/CategoryManagement';
import AddCategory from './pages/home/CategoryManagement/AddCategory';
import UpdateCategory from './pages/home/CategoryManagement/UpdateCategory';
import TypeManagement from './pages/home/TypeManagement/TypeManagement';
import UpdateType from './pages/home/TypeManagement/UpdateType';
import AddType from './pages/home/TypeManagement/AddType';
import DepartmentManagement from './pages/home/DepartmentManagement/DepartmentManagement';
import AddDepartment from './pages/home/DepartmentManagement/AddDepartment';
import UpdateDepartment from './pages/home/DepartmentManagement/UpdateDepartment';
import AddBrand from './pages/home/BrandManagement/AddBrand';
import BrandManagement from './pages/home/BrandManagement/BrandManagement';
import UpdateBrand from './pages/home/BrandManagement/UpdateBrand';
import InventoryStatusManagement from './pages/home/InventoryStatus/InventoryStatusManagement';
import AddInventoryStatus from './pages/home/InventoryStatus/AddInventoryStatus';
import UpdateInventoryStatus from './pages/home/InventoryStatus/UpdateInventoryStatus';
import DebitManagement from './pages/home/DebitManagement/DebitManagement';
import ResponsibleManagement from './pages/home/ResponsibleManagement/ResponsibleManagement';
import AddSupplier from './pages/home/SupplierManagement/AddSupplier';
import UpdateSupplier from './pages/home/SupplierManagement/UpdateSupplier';
import SupplierManagement from './pages/home/SupplierManagement/SupplierManagement';
import LocationManagement from './pages/home/LocationManagement/LocationManagement';
import AddLocation from './pages/home/LocationManagement/AddLocation';
import UpdateLocation from './pages/home/LocationManagement/UpdateLocation';
import CampusManagement from './pages/home/CampusManagement/CampusManagement';
import UpdateCampus from './pages/home/CampusManagement/UpdateCampus';
import AddCampus from './pages/home/CampusManagement/AddCampus';
import CountingManagement from './pages/home/CountingManagement/CountingManagement';
import InventoryManagement from './pages/home/InventoryManagement/InventoryManagement';
import AddInventory from './pages/home/InventoryManagement/AddInventory';
import UpdateInventory from './pages/home/InventoryManagement/UpdateInventory';
import AddCounting from './pages/home/CountingManagement/AddCounting';
import UpdateCounting from './pages/home/CountingManagement/UpdateCounting';
import DetailsCounting from './pages/home/CountingManagement/DetailsCounting';
import PrinterManagement from './pages/home/PrinterManagement/PrinterManagement';
import AddPrinter from './pages/home/PrinterManagement/AddPrinter';
import UpdatePrinter from './pages/home/PrinterManagement/UpdatePrinter';
import AddInventoryQuick from './pages/home/InventoryManagement/AddInventoryQuick';
import InventoryReportManagement from './pages/home/InventoryReportManagement/InventoryReportManagement';
import InventoryMovementManagement from './pages/home/InventoryMovementManagement/InventoryMovementManagement';
import InventoryReportCount from './pages/home/InventoryReportCount/InventoryReportCount';
import CountingAdd from './pages/home/CountingManagement/CountingAdd';
import CountingUpdate from './pages/home/CountingManagement/CountingUpdate';

//endregion

export interface IRoute {
  path: string;
  component: any;
  exact?: boolean;
  children?: IRoute[];
}

export const defaultRoutes: IRoute[] = [
  {
    path: '/dashboard',
    component: PageDashboard,
  },
  {
    path: '/user-settings',
    component: PageUserSettings,
  },
  {
    path: '/add-user',
    component: AddUser,
  },
  {
    path: '/update-user/:userId',
    component: UpdateUser,
  },
  {
    path: '/update-company/:companyId',
    component: UpdateCompany,
  },
  {
    path: '/reset-password',
    component: PasswordChange,
  },
  {
    path: '/change-email',
    component: EmailChange,
  },
  {
    path: '/printer-management',
    component: PrinterManagement,
  },
  {
    path: '/debit-management',
    component: DebitManagement,
  },
  {
    path: '/inventory-management',
    component: InventoryManagement,
  },
  {
    path: '/inventory-report-management',
    component: InventoryReportManagement,
  },
  {
    path: '/inventory-movement-management',
    component: InventoryMovementManagement,
  },
  {
    path: '/inventory-report-count',
    component: InventoryReportCount,
  },
  {
    path: '/category-management',
    component: CategoryManagement,
  },
  {
    path: '/type-management',
    component: TypeManagement,
  },
  {
    path: '/department-management',
    component: DepartmentManagement,
  },
  {
    path: '/brand-management',
    component: BrandManagement,
  },
  {
    path: '/inventory-status-management',
    component: InventoryStatusManagement,
  },
  {
    path: '/add-inventory-status',
    component: AddInventoryStatus,
  },
  {
    path: '/add-department',
    component: AddDepartment,
  },
  {
    path: '/add-category',
    component: AddCategory,
  },
  {
    path: '/add-type',
    component: AddType,
  },
  {
    path: '/add-brand',
    component: AddBrand,
  },
  {
    path: '/add-supplier',
    component: AddSupplier,
  },
  {
    path: '/add-inventory',
    component: AddInventory,
  },
  {
    path: '/add-inventory-quick',
    component: AddInventoryQuick,
  },
  {
    path: '/update-inventory/:inventoryId',
    component: UpdateInventory,
  },
  {
    path: '/update-category/:categoryId',
    component: UpdateCategory,
  },
  {
    path: '/update-supplier/:supplierId',
    component: UpdateSupplier,
  },
  {
    path: '/update-inventory-status/:inventoryStatusId',
    component: UpdateInventoryStatus,
  },
  {
    path: '/update-brand/:brandId',
    component: UpdateBrand,
  },
  {
    path: '/update-location/:locationId',
    component: UpdateLocation,
  },
  {
    path: '/update-department/:departmentId',
    component: UpdateDepartment,
  },
  {
    path: '/update-campus/:campusId',
    component: UpdateCampus,
  },
  {
    path: '/update-type/:typeId',
    component: UpdateType,
  },
  {
    path: '/templates',
    component: TemplatesPage,
  },
  {
    path: '/add-templates',
    component: AddTemplates,
  },
  {
    path: '/update-templates/:templatesId',
    component: UpdateTemplates,
  },
  {
    path: '/campus-management',
    component: CampusManagement,
  },
  {
    path: '/customer-management',
    component: CustomerManagement,
  },
  {
    path: '/responsible-management',
    component: ResponsibleManagement,
  },
  {
    path: '/supplier-management',
    component: SupplierManagement,
  },
  {
    path: '/location-management',
    component: LocationManagement,
  },
  {
    path: '/add-customer',
    component: AddCustomer,
  },
  {
    path: '/add-location',
    component: AddLocation,
  },
  {
    path: '/add-campus',
    component: AddCampus,
  },
  {
    path: '/company-management',
    component: CompanyManagement,
  },
  {
    path: '/printsJobs-excel',
    component: PrintsJobsExcel,
  },
  {
    path: '/printsJobsDetails-excel/:printsJobsId',
    component: PrintsJobsDetailsExcel,
  },
  {
    path: '/printsJobsDetails-api/:printsJobsId',
    component: PrintsJobsDetailsAPI,
  },
  {
    path: '/printsJobs-api',
    component: PrintsJobsAPI,
  },
  {
    path: '/counting-management',
    component: CountingManagement,
  },
  {
    path: '/add-counting',
    component: AddCounting,
  },
  {
    path: '/counting-add',
    component: CountingAdd,
  },
  {
    path: '/counting-update/:countingJobId',
    component: CountingUpdate,
  },
  {
    path: '/update-counting/:countingJobId',
    component: UpdateCounting,
  },
  {
    path: '/details-counting/:countingJobId',
    component: DetailsCounting,
  },
  {
    path: '/add-printer',
    component: AddPrinter,
  },
  {
    path: '/update-printer/:printerId',
    component: UpdatePrinter,
  },
];

export const publicRoutes: IRoute[] = [
  {
    path: '/sign-in',
    component: PageSignIn,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
];

export const routes: IRoute[] = [
  {
    path: '/admin',
    component: AdminLayout,
    children: defaultRoutes,
  },
  {
    path: '/public',
    component: PublicLayout,
    children: publicRoutes,
  },
];
